import { db } from '@/firebase/config';
import firebase from "firebase/app";
import "firebase/firestore";

export const changeMyWishesStatus = async (userId, wishId, decision, alumniId) => {
	try {
		const now = new firebase.firestore.Timestamp.now().toDate();
		let docRef;
		await db.collection("Profiles").doc(userId).collection("myWishes").where("wishId", "==", wishId).get().then(docs => {
			docs.forEach(doc => {
				docRef = doc.ref;
			})
		})
		await docRef.update({
			status: decision,
			alumniId: alumniId,
			lastStatusChangeTime: now
		})
		// console.log("changeMyWishesStatus activated");
	} catch (error) {
		alert("changeMyWishesStatus " + error);
	}
}

export default changeMyWishesStatus