import { db } from '@/firebase/config'
import firebase from "firebase/app";
import "firebase/firestore";
import createNewChat from "@/composables/Msg/createNewChat"
import sendChat from "@/composables/Msg/sendChat"

const now = new firebase.firestore.Timestamp.now().toDate();

const createAndSendChat = async (studentId, myId, content) => {
  try {
    let chatId = null
    let position = null
    await db.collection("Messages").where("chatterIds", "==", [studentId, myId]).get().then(docs => {
      docs.forEach(doc => {
        chatId = doc.id
        position = 1
      })
    })
    await db.collection("Messages").where("chatterIds", "==", [myId, studentId]).get().then(docs => {
      docs.forEach(doc => {
        chatId = doc.id
        position = 0
      })
    })
    if(chatId==null){
      chatId = await createNewChat(studentId, myId)
      position = 1
    }
    await sendChat(chatId, content, position, now)
  } catch (error) {
    alert("createAndSendChat " + error);
  }
};

export default createAndSendChat
